import * as React from "react";

const BagDimensions = ({ bag }) => {
  return (
    <div
      title="TEST"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          color: "beige",
          //   backgroundColor: "black",
          paddingTop: "4px",
          borderRadius: "13px",
          fontSize: "0.4rem",
        }}
      >
        <span title={`Højde: ${bag.height} cm`}>H:{bag.height}</span>
        <span style={{ paddingLeft: 3, paddingRight: 3 }}> X </span>
        <span title={`Bredde: ${bag.width} cm`}>
          B:
          {bag.width}
        </span>
        <span style={{ paddingLeft: 3, paddingRight: 3 }}> X </span>
        <span title={`Dybde: ${bag.depth} cm`}>D:{bag.depth} cm</span>
      </div>
    </div>
  );
};

export default BagDimensions;
