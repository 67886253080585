import * as React from "react";
import ImageGallery from "react-image-gallery";

const ShopGallery = (props) => {
  return (
    <div>
      <ImageGallery
        lazyLoad={true}
        loading={"lazy"}
        items={props.images || []}
        showPlayButton={false}
        showBullets={true}
        showThumbnails={false}
        showNav={false}
      />
    </div>
  );
};

export default ShopGallery;
