import { graphql } from "gatsby";
import React, { useState } from "react";
import BagCaption from "../components/BagCaption";
import BuyButton from "../components/BuyButton";
import Layout from "../components/Layout";
import ShopGallery from "../components/ShopGallery";
import "../styles/main.css";
import "../styles/reset.css";
import "../styles/shop.css";

function importAllShopImages(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}
const shopImages = importAllShopImages(
  require.context("../images/shop", false, /\.(webp)$/)
);

const getMainImag = function (id) {
  let property = `lefafa_${id}_1.webp`;
  let img = shopImages[property];
  if (img) {
    return img.default;
  } else {
    return "";
  }
};

const getImageSrc = function (path) {
  console.log("getIMgsrc called for pacth ", path);

  //const nameWithWebpExtension = path.replace(/(\..+)$/, `.webp`);

  //return nameWithWebpExtension;
  return path + ".webp";
};

const insertGallery = function (bag) {
  console.log("insert GAL called for bag ", bag);

  let images = [];

  images = bag.images.map((image) => ({
    original: getImageSrc(image.image),
  }));

  return <ShopGallery images={images} />;
};

const ShopPage = ({ data }) => {
  const [bags, setBags] = useState([]);
  React.useEffect(() => {
    const { edges } = data.allMarkdownRemark;

    const bagsFromEdges = edges.map((edge) => ({
      id: edge.node.id,
      bag_nr: edge.node.frontmatter.bag_nr,
      height: edge.node.frontmatter.height,
      width: edge.node.frontmatter.width,
      depth: edge.node.frontmatter.depth,
      price: edge.node.frontmatter.price,
      status: edge.node.frontmatter.status,
      images: edge.node.frontmatter.images,
    }));

    const hasNoImages = (bag) => bag.images;
    const isHidden = (bag) => bag.status !== "hidden";

    let sortedBags = bagsFromEdges.filter(hasNoImages).filter(isHidden);

    setBags(sortedBags);
  }, []);

  return (
    <Layout>
      <div className="bags">
        {bags.map((bag) => (
          <div className="bag">
            {insertGallery(bag)}

            <BagCaption bag={bag} />

            {/*       {bag.status === "available" ? (
                  <BuyButton
                    item={bag}
                    img={getImageSrc(bag.images[0].image)}
                  />
                ) : null} */}
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default ShopPage;

export const query = graphql`
  query getBagsQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
            bag_nr
            height
            width
            depth
            price
            status
            images {
              image
            }
          }
        }
      }
    }
  }
`;
