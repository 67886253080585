import { useTheme } from "@mui/styles";
import * as React from "react";
import Logo from "../components/Logo";
import BagDimensions from "./BagDimensions";

const BagCaption = (props) => {
  const theme = useTheme();

  function insertText() {
    switch (props.bag.status) {
      case "available":
        return `${props.bag.price} kr`;
        break;
      case "reserved":
        return (
          <div
            style={{
              padding: "4px",
              borderRadius: "2px",
              color: "white",
              marginTop: "20px",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            Reserveret
          </div>
        );
        break;
      case "sold":
        return (
          <div
            style={{
              padding: "4px",
              borderRadius: "2px",
              color: "white",
              marginTop: "20px",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            Solgt
          </div>
        );

      default:
        break;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          paddingTop: "15px",
          display: "flex",
          color: "beige",
          alignItems: "end",
          fontSize: "1rem",
        }}
      >
        <Logo color="beige" height="30" width="80" />
        <div
          style={{
            paddingLeft: "10px",
          }}
        >
          &#8470; {props.bag.bag_nr}
        </div>
      </div>
      <BagDimensions bag={props.bag} />

      {/*      <p
        style={{
          color: "beige",
          paddingTop: "10px",
          paddingLeft: "8px",
          paddingRight: "8px",
          borderRadius: "3px",
          fontSize: "0.9rem",
        }}
      >
        {insertText()}
      </p> */}
    </div>
  );
};

export default BagCaption;
